import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'react-tippy'
import { setRouteParams, OP_UPRAVIT } from '../../utils/routes'

class SubheaderFieldIcons extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { opCislo, canEditOp, textToCopy, t } = this.props

		const textToCopyIsEmpty = !textToCopy || textToCopy === '-' || textToCopy === ' ' || textToCopy === ''

		return (
			<div className='header-icon-container' style={{ ...this.props.style }}>
				{!textToCopyIsEmpty && (
					<Tooltip
						html={<span>{t('translation:UserHeaderIcons.Skopírovať')} </span>}
						style={{ display: 'inline-block' }}
						position='bottom'
						trigger='mouseenter'
						theme='light'
						disabled={false}
					>
						<a className='header-field-icon copy' onClick={() => navigator.clipboard.writeText(textToCopy)} />
					</Tooltip>
				)}
				{canEditOp && (
					<Tooltip
						html={<span>{t('translation:UserHeaderIcons.Upraviť')} </span>}
						style={{ display: 'inline-block' }}
						position='bottom'
						trigger='mouseenter'
						theme='light'
						disabled={false}
					>
						<Link to={setRouteParams(OP_UPRAVIT, opCislo)} className='header-field-icon edit' />
					</Tooltip>
				)}
			</div>
		)
	}
}

SubheaderFieldIcons.propTypes = {
	canEditOp: PropTypes.bool.isRequired,
	opCislo: PropTypes.string.isRequired,
	textToCopy: PropTypes.string,
	t: PropTypes.func.isRequired,
	style: PropTypes.shape()
}

SubheaderFieldIcons.defaultProps = {
	textToCopy: ''
}

export default withTranslation('components')(SubheaderFieldIcons)
