import { get, isEmpty, keys, map, upperFirst } from 'lodash'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm, reset, initialize, getFormValues, destroy } from 'redux-form'
import ElementFailure from '../../../components/ElementFailure'
import ElementEmptyContent from '../../../components/ElementEmptyContent'
import ElementLoading from '../../../components/ElementLoading'
import { openLiveAgentTicketPreview } from '../../../utils/files'
import { EXTERNAL_INTEGRATIONS, FORMS, NOTIFICATION_TYPES } from '../../../utils/enums'
import { getUkonStavString } from '../../../utils/notifikacie'
import { formatAddress } from '../../../utils/address'
import { formatDate } from '../../../utils/date'
import validate from './validateForm'
import { TextareaField } from '../../../atoms'
import { createFormInitValues } from '../../../utils/form'
import { putReq } from '../../../utils/request'

class UkonDetailEditInfo extends React.Component {
	static propTypes = {
		auth: PropTypes.shape().isRequired,
		ukonInterakcia: PropTypes.shape().isRequired,
		ukon: PropTypes.shape().isRequired,
		ukonData: PropTypes.shape().isRequired,
		ukonDataPred: PropTypes.shape().isRequired,
		dispatch: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		canEdit: PropTypes.bool
	}

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,
			isFailure: false,
			isEditModeActive: false
		}
	}

	async componentDidMount() {
		const { ukon, dispatch } = this.props

		const textarea = document.getElementById('noteTextarea')
		textarea.style.height = 'auto'
		textarea.style.height = `${textarea.scrollHeight}px`

		dispatch(
			initialize(
				FORMS.UKON_DETAIL_INFO_EDITACIA,
				createFormInitValues({
					ukonPoznamka: get(ukon, 'poznamka') || ''
				}),
				true
			)
		)
	}

	componentWillUnmount() {
		const { dispatch } = this.props
		dispatch(destroy(FORMS.UKON_DETAIL_INFO_EDITACIA))
	}

	getNotificationUriType = (type) => {
		const { t } = this.props

		switch (type) {
			case 'PEVNA_LINKA':
				return `(${t('translation:Notifications.Pevná linka')})`
			case 'EMAIL':
				return `(${t('translation:Notifications.Email')})`
			case 'FAX':
				return `(${t('translation:Notifications.Fax')})`
			case 'MOBIL':
				return `(${t('translation:Notifications.SMS')})`
			case 'URL':
				return `(${t('translation:Notifications.URL')})`
			default:
				return ''
		}
	}

	getNotificationValue = (notification) => {
		const { t } = this.props

		return get(notification, 'typ') != NOTIFICATION_TYPES.PRINTER ? get(notification, 'hodnota') : t('translation:Notifications.PRINTER')
	}

	handleSubmit = async () => {
		const { formValues, ukon, loadUkon, dispatch } = this.props

		this.setState({
			isLoading: true
		})

		try {
			const body = {
				poznamka: get(formValues, 'ukonPoznamka') || ''
			}

			await putReq(`/api/v2/ukony/${ukon.id}/editovat`, null, body)

			this.setState({
				isLoading: false
			})

			loadUkon()
		} catch (e) {
			dispatch(reset(FORMS.UKON_DETAIL_INFO_EDITACIA))
			this.setState({
				isLoading: false
			})
			// eslint-disable-next-line
			console.log(e)
		}
	}

	renderEditButton = () => {
		const { t, canEdit } = this.props
		let tootltipText = t('translation:Common.Upraviť poznámku')
		if (!canEdit) {
			tootltipText = t('containers:Na úpravu poznámky nemáte potrebné oprávnenia')
		}

		return (
			<Tooltip html={<span>{tootltipText}</span>} position='bottom' trigger='mouseenter' theme='light'>
				<button
					className={cx('button', { disabled: !canEdit })}
					data-type='icon-button'
					data-icon='edit'
					onClick={() => {
						if (canEdit) {
							this.setState({
								isEditModeActive: true
							})
						}
					}}
				/>
			</Tooltip>
		)
	}

	render() {
		const { isLoading, isFailure, isEditModeActive } = this.state

		const { ukon, ukonData, ukonDataPred, ukonInterakcia, t, invalid, dispatch } = this.props

		if (isLoading) {
			return <ElementLoading />
		}
		if (isFailure) {
			return <ElementFailure text={t('translation:Common.Nepodarilo sa načítať detail úkonu')} />
		}
		if (!ukon) {
			return <ElementEmptyContent text={t('translation:Common.Úkon sa nenašiel')} />
		}

		const notifikacieUri = map(get(ukon, 'notifikacie.adresyUri', []) || [], (notifikacia, index) => {
			return (
				<tr key={`adresyUri-${index}`}>
					<td>
						{t('translation:Common.Notifikácia')} {this.getNotificationUriType(notifikacia.typ)}
					</td>
					<td>
						<strong>{this.getNotificationValue(notifikacia)}</strong>
					</td>
				</tr>
			)
		})
		const notifikaciePosta = map(get(ukon, 'notifikacie.adresyPosta', []) || [], (adresa, index) => {
			const doRukMeno = get(adresa, 'doRukMeno') ? ` (${t('translation:Common.Do rúk')}: ${get(adresa, 'doRukMeno')})` : ''
			return (
				<tr key={`adresyPosta-${index}`}>
					<td>
						{t('translation:Common.Notifikácia')} ({t('translation:Common.Pošta')})
					</td>
					<td>
						<strong>
							{formatAddress(adresa, false)}
							{doRukMeno}
						</strong>
					</td>
				</tr>
			)
		})
		const notifikacieStav = map(keys(get(ukon, 'notifikacie.adresyStav')), (ukonStav) => {
			const stav = getUkonStavString(parseInt(ukonStav), t)
			let notifikacieUri = map(get(get(ukon, 'notifikacie.adresyStav')[ukonStav], 'adresyUri', []) || [], (notifikacia, index) => {
				return (
					<tr key={`adresyUri-${index}`}>
						<td>
							{t('translation:Common.Doplňujúca notifikácia pre stav')} {stav} {this.getNotificationUriType(notifikacia.typ)}
						</td>
						<td>
							<strong>{this.getNotificationValue(notifikacia)}</strong>
						</td>
					</tr>
				)
			})
			let notifikaciePosta = map(get(get(ukon, 'notifikacie.adresyStav')[ukonStav], 'adresyPosta', []) || [], (adresa, index) => {
				const doRukMeno = get(adresa, 'doRukMeno') ? ` (${t('containers:UkonDetailPage.Do rúk')}: ${get(adresa, 'doRukMeno')})` : ''
				return (
					<tr key={`adresyPosta-${index}`}>
						<td>
							{t('containers:UkonDetailPage.Doplňujúca notifikácia pre stav')} {stav} ({t('containers:UkonDetailPage.Pošta')})
						</td>
						<td>
							<strong>
								{formatAddress(adresa, false)}
								{doRukMeno}
							</strong>
						</td>
					</tr>
				)
			})
			return [...notifikacieUri, ...notifikaciePosta]
		})

		let typeSuffix = null
		if (get(ukon, 'typ.id') == 10) {
			if (!isEmpty(get(ukonData, 'eFakturaEmaily', [])) && isEmpty(get(ukonDataPred, 'eFakturaEmaily', []))) {
				typeSuffix = ` (${t('translation:Common.Aktivácia e-faktúry')})`
			} else if (isEmpty(get(ukonData, 'eFakturaEmaily', [])) && !isEmpty(get(ukonDataPred, 'eFakturaEmaily', []))) {
				typeSuffix = ` (${t('translation:Common.Deaktivácia e-faktúry')})`
			}
		}

		const liveAgentTicket = get(ukonInterakcia, 'externeIDs', []).find((externeID) => {
			return externeID.source === EXTERNAL_INTEGRATIONS.LIVE_AGENT
		})

		const finesse = get(ukonInterakcia, 'externeIDs', []).find((externeID) => {
			return externeID.source === EXTERNAL_INTEGRATIONS.FINESSE
		})

		const call250 = get(ukonInterakcia, 'externeIDs', []).find((externeID) => {
			return externeID.source === EXTERNAL_INTEGRATIONS.CALL250
		})

		return (
			<div className='box'>
				<div className='box-content'>
					<div className='row'>
						<div className='col-12'>
							<div className='inner-box'>
								<table>
									<tbody>
										<tr>
											<td width='30%'>{t('translation:Common.ID')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'id') || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Číslo interakcie')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'interakciaId') || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%' style={{ verticalAlign: 'top' }}>
												{t('translation:Common.Poznámka z interakcie')}
											</td>
											<td width='70%' style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontWeight: 'bold' }}>
												<div className='input-wrapper'>{get(ukonInterakcia, 'poznamka') || '-'}</div>
											</td>
										</tr>
										{liveAgentTicket?.externalId && (
											<tr>
												<td width='30%'>{t('translation:Common.LiveAgent ticket interakcie')}</td>
												<td width='70%'>
													<a
														className='file'
														onClick={() => openLiveAgentTicketPreview(liveAgentTicket.externalId)}
														data-type='general'
														style={{ cursor: 'pointer' }}
													>
														<strong>{liveAgentTicket.externalId}</strong>
													</a>
												</td>
											</tr>
										)}
										{finesse?.externalId && (
											<tr>
												<td width='30%'>{t('translation:Common.ID hovoru z interakcie')}</td>
												<td width='70%'>
													<strong>{finesse.externalId}</strong>
												</td>
											</tr>
										)}
										{call250?.externalId && (
											<tr>
												<td width='30%'>{t('translation:Common.ID vyvolania z interakcie')}</td>
												<td width='70%'>
													<strong>{call250.externalId}</strong>
												</td>
											</tr>
										)}
										<tr>
											<td width='30%'>{t('translation:Common.Typ úkonu')}</td>
											<td width='70%'>
												<strong>
													{upperFirst(get(ukon, 'typ.nazov') || '-')}
													{typeSuffix}
												</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Stav úkonu')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'ukonStav.nazov') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Vstup do úkonu')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'vstup.nazov') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Splnomocnenec')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'splnomocnenec') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Miesto podpisu úkonu')}</td>
											<td width='70%'>
												<strong>{upperFirst(get(ukon, 'podpisMiesto') || '-')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Dátum prijatia žiadosti')}</td>
											<td width='70%'>
												<strong>{formatDate(get(ukon, 'ziadanyOd'), '-', 'DD.MM.YYYY')}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Pracovník')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'riesitel') || '-'}</strong>
											</td>
										</tr>
										<tr>
											<td width='30%'>{t('translation:Common.Kanál')}</td>
											<td width='70%'>
												<strong>{get(ukon, 'kanal.popis') || '-'}</strong>
											</td>
										</tr>
										{notifikacieUri}
										{notifikaciePosta}
										{isEmpty(notifikacieUri) && isEmpty(notifikaciePosta) && (
											<tr>
												<td>{t('translation:Common.Notifikácia')}</td>
												<td>
													<strong>-</strong>
												</td>
											</tr>
										)}
										{notifikacieStav}
										<tr>
											<>
												<td width='30%' style={{ verticalAlign: 'top' }}>
													{t('translation:Common.Poznámka')}
												</td>
												{!isEditModeActive && (
													<td width='70%'>
														<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
															<div style={{ width: '95%' }}>
																<textarea
																	id={'noteTextarea'}
																	style={{
																		color: '#191919',
																		width: '100%',
																		fontWeight: '700',
																		fontSize: '14px',
																		border: 'none',
																		backgroundColor: 'transparent',
																		overflowY: 'hidden'
																	}}
																	disabled
																	value={isEmpty(get(ukon, 'poznamka')) ? '-' : get(ukon, 'poznamka')}
																/>
															</div>
															{this.renderEditButton()}
														</div>
													</td>
												)}

												{isEditModeActive && (
													<td width='70%' style={{ textAlign: 'right' }}>
														<form
															onSubmit={(e) => {
																e.preventDefault()
																this.handleSubmit()
															}}
														>
															<Field
																name={'ukonPoznamka'}
																component={TextareaField}
																isDisabled={!isEditModeActive}
																rows={isEmpty(get(ukon, 'poznamka')) && !isEditModeActive ? 1 : 7}
																className={cx('form-control')}
															/>
															<div>
																<button
																	className={cx('button', { disabled: invalid })}
																	data-color='blue'
																	style={{ marginRight: '20px' }}
																	type='submit'
																>
																	{t('translation:Common.Uložiť')}
																</button>
																<button
																	type='button'
																	className='button'
																	data-type='outline'
																	data-color='red'
																	onClick={() => {
																		dispatch(reset(FORMS.UKON_DETAIL_INFO_EDITACIA))
																		this.setState({
																			isEditModeActive: false
																		})
																	}}
																>
																	{t('translation:Common.Zrušiť')}
																</button>
															</div>
														</form>
													</td>
												)}
											</>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className='col-8' />
					</div>
				</div>
			</div>
		)
	}
}

const form = reduxForm({
	form: FORMS.UKON_DETAIL_INFO_EDITACIA,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(UkonDetailEditInfo)

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

const mapStateToProps = (state) => ({
	auth: get(state, 'auth'),
	formValues: getFormValues(FORMS.UKON_DETAIL_INFO_EDITACIA)(state)
})

export default compose(withTranslation('containers'), connect(mapStateToProps, mapDispatchToProps))(form)
