import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// utils
import { formatFormValue } from '../../utils/form'
import { formatDate } from '../../utils/date'

function UkonOverenieUdajovOP(props) {
	const { ukonData, ukonDataPred, t, formatDocuments } = props

	const aktualnostTelefon = formatDate(get(ukonData, 'kontaktneUdajeOverenie.aktualnostTelefon'), '-', 'DD.MM.YYYY HH:mm')
	const aktualnostTelefonPred = formatDate(get(ukonDataPred, 'kontaktneUdajeOverenie.aktualnostTelefon'), '-', 'DD.MM.YYYY HH:mm')

	const aktualnostEmail = formatDate(get(ukonData, 'kontaktneUdajeOverenie.aktualnostEmail'), '-', 'DD.MM.YYYY HH:mm')
	const aktualnostEmailPred = formatDate(get(ukonDataPred, 'kontaktneUdajeOverenie.aktualnostEmail'), '-', 'DD.MM.YYYY HH:mm')

	const aktualnostAdresaZakaznika = formatDate(get(ukonData, 'kontaktneUdajeOverenie.aktualnostAdresaZakaznika'), '-', 'DD.MM.YYYY HH:mm')
	const aktualnostAdresaZakaznikaPred = formatDate(get(ukonDataPred, 'kontaktneUdajeOverenie.aktualnostAdresaZakaznika'), '-', 'DD.MM.YYYY HH:mm')

	const aktualnostAdresa = formatDate(get(ukonData, 'kontaktneUdajeOverenie.aktualnostAdresa'), '-', 'DD.MM.YYYY HH:mm')
	const aktualnostAdresaPred = formatDate(get(ukonDataPred, 'kontaktneUdajeOverenie.aktualnostAdresa'), '-', 'DD.MM.YYYY HH:mm')

	return (
		<tbody>
			<tr>
				<td>
					<strong>{t('translation:Common.Dátum aktualizácie pre kontaktný telefón')}</strong>
				</td>
				<td>{aktualnostTelefonPred}</td>
				<td>{formatFormValue(aktualnostTelefon, aktualnostTelefonPred)}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Dátum aktualizácie pre kontaktný email')}</strong>
				</td>
				<td>{aktualnostEmailPred}</td>
				<td>{formatFormValue(aktualnostEmail, aktualnostEmailPred)}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Dátum aktualizácie pre adresu trvalého pobytu')}</strong>
				</td>
				<td>{aktualnostAdresaZakaznikaPred}</td>
				<td>{formatFormValue(aktualnostAdresaZakaznika, aktualnostAdresaZakaznikaPred)}</td>
			</tr>
			<tr>
				<td>
					<strong>{t('translation:Common.Dátum aktualizácie pre korešpondenčnú adresa')}</strong>
				</td>
				<td>{aktualnostAdresaPred}</td>
				<td>{formatFormValue(aktualnostAdresa, aktualnostAdresaPred)}</td>
			</tr>

			<tr>
				<td colSpan={3}>{formatDocuments}</td>
			</tr>
		</tbody>
	)
}

UkonOverenieUdajovOP.propTypes = {
	ukonData: PropTypes.shape(),
	ukonDataPred: PropTypes.shape(),
	t: PropTypes.func.isRequired,
	formatDocuments: PropTypes.func.isRequired
}

export default UkonOverenieUdajovOP
