import { get } from 'lodash'

// utils
import { formatAddressForResponse } from '../../utils/address'
import { UKONY_CISELNIK } from '../../utils/enums'

// components
import OdpovedTextField from '../../components/GenericUkon/fields/OdpovedTextField'
import OdberneMiestoField from '../../components/GenericUkon/fields/OdberneMiestoField'
import ProduktField from '../../components/GenericUkon/fields/ProduktField'
import ProduktovaRodinaField from '../../components/GenericUkon/fields/ProduktovaRodinaField'
import ProduktVsdSsdField from '../../components/GenericUkon/fields/ProduktVsdSsdField'
import PozadovanyDatumField from '../../components/GenericUkon/fields/PozadovanyDatumField'
import PozadovanyDatumReadOnlyField from '../../components/GenericUkon/fields/PozadovanyDatumReadOnlyField'
import ZmluvnyUcetField from '../../components/GenericUkon/fields/ZmluvnyUcet/ZmluvnyUcetField'
import BooleanField from '../../components/GenericUkon/fields/BooleanField'
import OnlyYesConfirmField from '../../components/GenericUkon/fields/OnlyYesConfirmField'
import OdpocetField from '../../components/GenericUkon/fields/OdpocetField'
import ZalohyField from '../../components/GenericUkon/fields/ZalohyField'
import UcelPouzitiaGasField from '../../components/GenericUkon/fields/UcelPouzitiaGasField'
import DovodUkonceniaField from '../../components/GenericUkon/fields/DovodUkonceniaField'
import RelatedUkonField from '../../components/GenericUkon/fields/RelatedUkonField'
import TextField from '../../components/GenericUkon/fields/TextField'
import DokumentyField from '../../components/GenericUkon/fields/DokumentyField'
import VystupneDokumentyField from '../../components/GenericUkon/fields/VystupneDokumentyField'
import ZmluvnyVztahCisloIsuField from '../../components/GenericUkon/fields/ZmluvnyVztahCisloIsuField'
import ZmluvnyVztahCisloField from '../../components/GenericUkon/fields/ZmluvnyVztahCisloField'
import MiestoSpotrebyField from '../../components/GenericUkon/fields/MiestoSpotrebyField'
import VyrocieDKField from '../../components/GenericUkon/fields/VyrocieDKField'
import DistribucneCisloOPField from '../../components/GenericUkon/fields/DistribucneCisloOPField'
import PoznamkaSPPDField from '../../components/GenericUkon/fields/PoznamkaSPPDField'
import PreukazTypField from '../../components/GenericUkon/fields/PreukazTypField'
import PreukazCisloField from '../../components/GenericUkon/fields/PreukazCisloField'
import ElektromobilField from '../../components/GenericUkon/fields/ElektromobilField'
import SubjektZuctovaniaField from '../../components/GenericUkon/fields/SubjektZuctovaniaField'
import UkonKategoriaField from '../../components/GenericUkon/fields/UkonKategoriaField'
import VstupDoUkonuField from '../../components/GenericUkon/fields/VstupDoUkonuField'
import MiestoSpotrebyVyberField from '../../components/GenericUkon/fields/MiestoSpotrebyVyberField'
import SplnomocnenecField from '../../components/GenericUkon/fields/SplnomocnenecField'
import PodpisMiestoField from '../../components/GenericUkon/fields/PodpisMiestoField'
import DatumPrijatiaZiadostiField from '../../components/GenericUkon/fields/DatumPrijatiaZiadostiField'
import NotifikaciaField from '../../components/GenericUkon/fields/NotifikaciaField'
import FazyPocetField from '../../components/GenericUkon/fields/FazyPocetField'
import HlavnyIsticHodnotaField from '../../components/GenericUkon/fields/HlavnyIsticHodnotaField'
import DatumUmrtiaField from '../../components/GenericUkon/fields/DatumUmrtiaField'
import KorespondencnaAdresaField from '../../components/GenericUkon/fields/KorespondencnaAdresaField'
import AdresaPostovePoukazkyField from '../../components/GenericUkon/fields/AdresaPostovePoukazkyField'
import UmrtieTypEvidencieField from '../../components/GenericUkon/fields/UmrtieTypEvidencieField'
import EmailEfaktura from '../../components/GenericUkon/fields/EmailEfaktura'
import SprostredkovaniePoisteniaField from '../../components/GenericUkon/fields/SprostredkovaniePoisteniaField'
import OznamovatelUmrtiaField from '../../components/GenericUkon/fields/OznamovatelUmrtiaField'
import KontaktnyEmailField from '../../components/GenericUkon/fields/KontaktnyEmailField'
import ZmluvnyUcetCisloField from '../../components/GenericUkon/fields/ZmluvnyUcetCisloField'

export const EDIT_MODE = {
	LOOKUP: 'LOOKUP',
	CREATE: 'CREATE',
	EDIT: 'EDIT',
	CONFIRM: 'CONFIRM',
	DETAIL: 'DETAIL'
}

export const COLUMNS_COUNT = {
	TWO: 'TWO',
	THREE: 'THREE'
}

export const STEP = {
	ONE: 1,
	TWO: 2,
	THREE: 3,
	FOUR: 4
}

export const FIELD_PATH = {
	DOKUMENTY: 'dokumenty',
	NOTIFIKACIE: 'notifikacie'
}

export const BASE_CONFIG = {
	steps: STEP.TWO,
	defaultStep: STEP.ONE,
	columnsCount: COLUMNS_COUNT.THREE,
	fields: {
		AttrOdberneMiestoDTO: {
			label: 'Odberné miesto',
			fieldComponent: OdberneMiestoField,
			destroy: (props, attribute) => {
				const { formValues, ukonyActions, odberneMiestaActions } = props

				const omCislo = get(formValues, `${get(attribute, 'cesta')}.cislo`)
				if (omCislo) {
					ukonyActions.clearUkonyForOdberneMiesto(omCislo)
				}
				odberneMiestaActions.searchOdberneMiestaClear()
			}
		},
		AttrProduktDTO: {
			fieldComponent: ProduktField
		},
		AttrProduktVsdSsdDTO: {
			fieldComponent: ProduktVsdSsdField
		},
		AttrProduktovaRodinaDTO: {
			fieldComponent: ProduktovaRodinaField
		},
		AttrPozadovanyDatumDTO: {
			fieldComponent: PozadovanyDatumField
		},
		AttrPozadovanyDatumReadOnlyDTO: {
			fieldComponent: PozadovanyDatumReadOnlyField
		},
		AttrZmluvnyUcetDTO: {
			fieldComponent: ZmluvnyUcetField,
			format: (props, value) => {
				const { addresses } = props

				const adresaFakturacie = formatAddressForResponse('adresaFakturacie', value, addresses)

				return {
					...value,
					adresaFakturacie
				}
			}
		},
		AttrPohladavkyUhradeneDTO: {
			label: 'Uhradené pohľadávky',
			fieldComponent: BooleanField
		},
		AttrUzivaciePravoPreukazaneDTO: {
			label: 'Preukázané užívacie právo k OM',
			fieldComponent: BooleanField
		},
		AttrLimitovanaObsluhaSchvaleneDTO: {
			label: 'Požiadavka bola schválená (neplatici@zse.sk)',
			fieldComponent: BooleanField
		},
		AttrOdpocetDTO: {
			label: 'Stav meradla',
			fieldComponent: OdpocetField,
			validateMaxHodnotaOdpoctu: false
		},
		AttrPredpokladanaRocnaSpotrebaDTO: {
			label: 'Predpokladaná ročná spotreba',
			showOnlyModes: [EDIT_MODE.CONFIRM, EDIT_MODE.DETAIL],
			hideBeforeValue: true,
			fieldComponent: OdpocetField,
			validateMaxHodnotaOdpoctu: true
		},
		AttrZalohyDTO: {
			fieldComponent: ZalohyField
		},
		AttrUcelPouzitiaGasDTO: {
			fieldComponent: UcelPouzitiaGasField
		},
		AttrDovodUkonceniaDTO: {
			fieldComponent: DovodUkonceniaField
		},
		AttrZmluvaNovaUkonIdDTO: {
			label: 'Súvisiaci úkon novej zmluvy',
			buttonLabel: 'Nová zmluva',
			fieldComponent: RelatedUkonField,
			readonly: true
		},
		AttrZmluvaPrepisUkonIdDTO: {
			label: 'Súvisiaci úkon ukončenia zmluvy',
			buttonLabel: 'Ukončenie zmluvy',
			fieldComponent: RelatedUkonField,
			readonly: true
		},
		AttrPoznamkaDTO: {
			fieldComponent: TextField,
			label: 'Poznámka',
			placeholder: 'Zadajte poznámku'
		},
		AttrTextZiadostiDTO: {
			fieldComponent: TextField,
			label: 'Text žiadosti',
			placeholder: 'Zadajte text žiadosti'
		},
		AttrOdpovedTextDTO: {
			fieldComponent: OdpovedTextField
		},
		AttrUkonDokumentyVstupneDTO: {
			fieldComponent: DokumentyField
		},
		AttrUkonDokumentyVystupneDTO: {
			fieldComponent: VystupneDokumentyField,
			step: STEP.TWO
		},
		AttrNotifikaciaDTO: {
			fieldComponent: NotifikaciaField,
			step: STEP.TWO
		},
		AttrZmluvnyUcetCisloDTO: {
			fieldComponent: ZmluvnyUcetCisloField
		},
		AttrZmluvnyVztahCisloIsuDTO: {
			fieldComponent: ZmluvnyVztahCisloIsuField
		},
		AttrZmluvnyVztahCisloDTO: {
			fieldComponent: ZmluvnyVztahCisloField
		},
		AttrZopPotvrdenaDTO: {
			label: 'Zmluva o pripojení (ZoP) potvrdená',
			fieldComponent: OnlyYesConfirmField
		},
		AttrMiestoSpotrebyDTO: {
			label: 'Miesto spotreby',
			fieldComponent: MiestoSpotrebyField,
			destroy: (props) => {
				const { odberneMiestaActions, miestaSpotrebyActions } = props

				odberneMiestaActions.searchOdberneMiestaClear()
				miestaSpotrebyActions.searchMiestaSpotrebyClear()
			}
		},
		AttrMiestoSpotrebyVyberDTO: {
			label: 'Vyberte miesto spotreby',
			fieldComponent: MiestoSpotrebyVyberField
		},
		AttrElektromobilDTO: {
			fieldComponent: ElektromobilField
		},
		AttrPriPotvrdenaDTO: {
			formatLabel: (props) => {
				const typ = get(props, 'novaZmluvaTyp', '')
				return `Zmluva o pripojení (${typ}) potvrdená`
			},
			tooltip: 'Overenie platnosti zmluvy o Pripojení na požadované parametre zodpovedajúcemu obchodnému produktu',
			fieldComponent: OnlyYesConfirmField
		},
		AttrTechnickaZmenaOMDTO: {
			label: 'Podaná Žiadosť o vyjadrenie k technickej zmene na OM',
			fieldComponent: BooleanField
		},
		AttrAdresaSppdDTO: {
			label: 'Miesto spotreby',
			fieldComponent: MiestoSpotrebyField,
			destroy: (props) => {
				const { odberneMiestaActions, miestaSpotrebyActions } = props

				odberneMiestaActions.searchOdberneMiestaClear()
				miestaSpotrebyActions.searchMiestaSpotrebyClear()
			}
		},
		AttrVyrocieDKDTO: {
			fieldComponent: VyrocieDKField
		},
		AttrDistribucneOPCisloDTO: {
			fieldComponent: DistribucneCisloOPField
		},
		AttrGasPodmienkySplneneDTO: {
			label: 'Splnené podmienky SPP-D',
			fieldComponent: OnlyYesConfirmField
		},
		AttrOmVyrobaDTO: {
			label: 'Je na OM výroba?',
			fieldComponent: BooleanField
		},
		AttrInformaciaPreSppdDTO: {
			fieldComponent: PoznamkaSPPDField
		},
		AttrPreukazTypDTO: {
			fieldComponent: PreukazTypField
		},
		AttrPreukazCisloDTO: {
			fieldComponent: PreukazCisloField
		},
		AttrOkamzitaDodavkaSuhlasDTO: {
			label: 'Súhlas s okamžitou dodávkou',
			fieldComponent: BooleanField
		},
		AttrViazanostDTO: {
			label: 'Viazanosť na dobu určitú?',
			fieldComponent: BooleanField
		},
		AttrSubjektZuctovaniaDTO: {
			label: 'Subjekt zúčtovania',
			fieldComponent: SubjektZuctovaniaField
		},
		AttrUkonKategoria1DTO: {
			fieldComponent: UkonKategoriaField,
			optionsData: 'ukonKategoria1',
			label: 'Kategória 1'
		},
		AttrUkonKategoria2DTO: {
			fieldComponent: UkonKategoriaField,
			optionsData: 'ukonKategoria2',
			label: 'Kategória 2'
		},
		AttrUkonKategoria3DTO: {
			fieldComponent: UkonKategoriaField,
			optionsData: 'ukonKategoria3',
			label: 'Kategória 3'
		},
		AttrUkonVstupDTO: {
			fieldComponent: VstupDoUkonuField
		},
		AttrSplnomocnenecDTO: {
			fieldComponent: SplnomocnenecField
		},
		AttrMiestoPodpisuDTO: {
			fieldComponent: PodpisMiestoField
		},
		AttrDatumPrijatiaZiadostiDTO: {
			fieldComponent: DatumPrijatiaZiadostiField
		},
		AttrFazyPocetDTO: {
			fieldComponent: FazyPocetField
		},
		AttrHlavnyIsticHodnotaDTO: {
			fieldComponent: HlavnyIsticHodnotaField
		},
		AttrUkonDokumentyPrilohyDTO: {
			fieldComponent: DokumentyField,
			label: 'Prílohy'
		},
		AttrSprostredkovaniePoisteniaDTO: {
			fieldComponent: SprostredkovaniePoisteniaField
		},
		AttrOPUmrtieDatumDTO: {
			fieldComponent: DatumUmrtiaField
		},
		AttrOPAdresaKorespondencnaDTO: {
			fieldComponent: KorespondencnaAdresaField
		},
		AttrOPKontaktnyEmailDTO: {
			fieldComponent: KontaktnyEmailField
		},
		AttrOPAdresaOdoslanePlatbyDTO: {
			fieldComponent: AdresaPostovePoukazkyField
		},
		AttrOPUmrtieTypEvidencieDTO: {
			fieldComponent: UmrtieTypEvidencieField
		},
		AttrOPEmailEfakturaDTO: {
			fieldComponent: EmailEfaktura
		},
		AttrOznamovatelUmrtiaDTO: {
			fieldComponent: OznamovatelUmrtiaField
		}
	}
}

const NOVE_ZMLUVY_CONFIG = {
	steps: STEP.THREE,
	defaultStep: STEP.TWO,
	columnsCount: COLUMNS_COUNT.THREE,
	stepsConfig: {
		[STEP.ONE]: {
			mode: EDIT_MODE.LOOKUP
		},
		[STEP.TWO]: {
			mode: EDIT_MODE.EDIT
		},
		[STEP.THREE]: {
			mode: EDIT_MODE.CONFIRM
		}
	},
	fields: {
		...BASE_CONFIG.fields,
		AttrOdberneMiestoDTO: {
			...BASE_CONFIG.fields.AttrOdberneMiestoDTO,
			step: [STEP.ONE, STEP.TWO]
		},
		AttrMiestoSpotrebyDTO: {
			...BASE_CONFIG.fields.AttrMiestoSpotrebyDTO,
			step: [STEP.ONE, STEP.TWO]
		},
		AttrUkonVstupDTO: {
			...BASE_CONFIG.fields.AttrUkonVstupDTO,
			step: STEP.TWO
		},
		AttrNotifikaciaDTO: {
			...BASE_CONFIG.fields.AttrNotifikaciaDTO,
			step: STEP.THREE
		},
		AttrUkonDokumentyVystupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVystupneDTO,
			step: STEP.THREE
		}
	}
}

const NOVE_ZMLUVY_PREPIS_CONFIG = {
	steps: STEP.FOUR,
	defaultStep: STEP.THREE,
	columnsCount: COLUMNS_COUNT.THREE,
	stepsConfig: {
		[STEP.ONE]: {
			mode: EDIT_MODE.LOOKUP
		},
		[STEP.TWO]: {
			mode: EDIT_MODE.LOOKUP
		},
		[STEP.THREE]: {
			mode: EDIT_MODE.EDIT
		},
		[STEP.FOUR]: {
			mode: EDIT_MODE.CONFIRM
		}
	},
	fields: {
		...BASE_CONFIG.fields,
		AttrOdberneMiestoDTO: {
			...BASE_CONFIG.fields.AttrOdberneMiestoDTO,
			step: [STEP.ONE, STEP.THREE]
		},
		AttrMiestoSpotrebyDTO: {
			...BASE_CONFIG.fields.AttrMiestoSpotrebyDTO,
			step: [STEP.TWO, STEP.THREE]
		},
		AttrUkonVstupDTO: {
			...BASE_CONFIG.fields.AttrUkonVstupDTO,
			step: STEP.THREE
		},
		AttrNotifikaciaDTO: {
			...BASE_CONFIG.fields.AttrNotifikaciaDTO,
			step: STEP.FOUR
		},
		AttrUkonDokumentyVystupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVystupneDTO,
			step: STEP.FOUR
		}
	}
}

const NOVE_ZMLUVY_OBNOVA_MS_CONFIG = {
	steps: STEP.THREE,
	defaultStep: STEP.TWO,
	columnsCount: COLUMNS_COUNT.THREE,
	stepsConfig: {
		[STEP.ONE]: {
			mode: EDIT_MODE.LOOKUP
		},
		[STEP.TWO]: {
			mode: EDIT_MODE.EDIT
		},
		[STEP.THREE]: {
			mode: EDIT_MODE.CONFIRM
		}
	},
	fields: {
		...BASE_CONFIG.fields,
		AttrNotifikaciaDTO: {
			...BASE_CONFIG.fields.AttrNotifikaciaDTO,
			step: STEP.THREE
		},
		AttrUkonDokumentyVystupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVystupneDTO,
			step: STEP.THREE
		}
	}
}

const SYSTEMOVE_LISTY_CONFIG = {
	steps: STEP.THREE,
	defaultStep: STEP.ONE,
	columnsCount: COLUMNS_COUNT.TWO,
	stepsConfig: {
		[STEP.ONE]: {
			mode: EDIT_MODE.CREATE
		},
		[STEP.TWO]: {
			mode: EDIT_MODE.CREATE
		},
		[STEP.THREE]: {
			mode: EDIT_MODE.CONFIRM
		}
	},
	fields: {
		...BASE_CONFIG.fields,
		AttrUkonDokumentyVstupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVstupneDTO,
			step: STEP.ONE
		},
		AttrOdpovedTextDTO: {
			...BASE_CONFIG.fields.AttrOdpovedTextDTO,
			step: STEP.TWO
		},
		AttrUkonDokumentyPrilohyDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyPrilohyDTO,
			step: STEP.TWO
		},
		AttrNotifikaciaDTO: {
			...BASE_CONFIG.fields.AttrNotifikaciaDTO,
			step: STEP.THREE
		},
		AttrUkonDokumentyVystupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVystupneDTO,
			step: STEP.THREE
		}
	}
}

const VSEOBECNY_UKON_AKTIVNY_CONFIG = {
	steps: STEP.TWO,
	defaultStep: STEP.ONE,
	columnsCount: COLUMNS_COUNT.TWO,
	stepsConfig: {
		[STEP.ONE]: {
			mode: EDIT_MODE.CREATE
		},
		[STEP.THREE]: {
			mode: EDIT_MODE.CONFIRM
		}
	},
	fields: {
		...BASE_CONFIG.fields,
		AttrUkonDokumentyVstupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVstupneDTO,
			step: STEP.ONE
		},
		AttrUkonDokumentyVystupneDTO: {
			...BASE_CONFIG.fields.AttrUkonDokumentyVystupneDTO,
			step: STEP.TWO
		}
	}
}

const BASE_CONFIG_TWO_COLUMNS = {
	...BASE_CONFIG,
	columnsCount: COLUMNS_COUNT.TWO
}

export const getGenericUkonConfig = (ukonTyp) => {
	if (!ukonTyp?.id) {
		return BASE_CONFIG
	}
	switch (ukonTyp.id) {
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY:
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_EE:
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_PREPIS_GAS:
			return NOVE_ZMLUVY_CONFIG
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE:
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS:
			return NOVE_ZMLUVY_PREPIS_CONFIG
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS:
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS:
			return NOVE_ZMLUVY_OBNOVA_MS_CONFIG
		case UKONY_CISELNIK.SYSTEMOVE_LISTY:
			return SYSTEMOVE_LISTY_CONFIG
		case UKONY_CISELNIK.VSEOBECNY_UKON_AKTIVNY:
			return VSEOBECNY_UKON_AKTIVNY_CONFIG
		case UKONY_CISELNIK.VSEOBECNY_UKON_PASIVNY:
		case UKONY_CISELNIK.ANONYMNY_VSEOBECNY_UKON:
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_VAS:
		case UKONY_CISELNIK.ZMLUVNY_VZTAH_NOVY_DODATOK:
			return BASE_CONFIG_TWO_COLUMNS
		default:
			return BASE_CONFIG
	}
}
