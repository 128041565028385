import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm, propTypes, getFormValues, Fields } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { every, map, get, isEmpty, some, filter, find } from 'lodash'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Tooltip } from 'react-tippy'

// components
import ElementLoading from '../../../ElementLoading'
import SearchResultTableRow from '../../../TableRows/SearchResultTableRow'

// resources
import SearchIcon from '../../../../resources/img/icons/search-blue.svg'

import validate from './validateForm'

// atoms
import { SelectField, DatePickerField, TextInputField, AddressField } from '../../../../atoms'

// utils
import { FORMS, OBCHODNY_PARTNER_OSLOVENIE, OP_SKUPINA_OPRAVNENI } from '../../../../utils/enums'
import { formatAddress, formatAddressForResponse } from '../../../../utils/address'
import { setRouteParams, VZTAHY_ZOZNAM } from '../../../../utils/routes'
import { celeMeno } from '../../../../utils/obchodnyPartner'
import { isValidacneKriteriumError } from '../../../../utils/form'

class SplnomocneniaSearch extends React.Component {
	static propTypes = {
		...propTypes,
		interakcia: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				opCislo: PropTypes.string.isRequired
			}).isRequired,
			isLoading: PropTypes.bool.isRequired
		}).isRequired,
		initialValues: PropTypes.shape(),
		t: PropTypes.func
	}

	constructor(props) {
		super(props)

		this.state = {
			titulPred2Focus: false,
			searchSlug: '',
			titulyPredMenomOptions: [],
			titulyZaMenomOptions: [],
			druhOptions: [],
			oslovenieOptions: []
		}
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true

		const { ciselniky } = this.props

		const titulyPredMenom = filter(get(ciselniky, 'akademickyTitulHodnota', []), 'pred_menom')
		const titulyZaMenom = filter(get(ciselniky, 'akademickyTitulHodnota', []), ['pred_menom', false])

		this.setState({
			titulyPredMenomOptions: map(titulyPredMenom, (item) => ({
				value: item.id,
				label: item.nazov
			})),
			titulyZaMenomOptions: map(titulyZaMenom, (item) => ({
				value: item.id,
				label: item.nazov
			})),
			druhOptions: map(get(ciselniky, 'obchodnyPartnerDruh'), (item) => ({
				value: item.id,
				label: item.nazov
			})),
			oslovenieOptions: map(
				filter(get(ciselniky, 'obchodnyPartnerOslovenie'), (item) => item.id != OBCHODNY_PARTNER_OSLOVENIE.FIRMA),
				(item) => ({
					value: item.id,
					label: item.nazov
				})
			)
		})
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {
		const { formValues, change } = this.props
		const { titulPred1, titulPred2 } = formValues

		if (!titulPred1 && titulPred2) {
			change('titulPred1', titulPred2)
			change('titulPred2', null)
		}
	}

	startSearch = () => {
		const { statuses, statusActions, searchActions, change } = this.props
		const { searchSlug } = this.state

		// prevent to dispach search user action if search string is empty
		if (searchSlug) {
			// clean previous error messages
			if (statuses.length > 0) {
				statusActions.statusPop(statuses.length)
			}

			// refresh previous selectedOP
			change('selectedOP', null)

			const query = {
				skupinyOpravneni: OP_SKUPINA_OPRAVNENI.MOO
			}

			searchActions.searchUsers(searchSlug, query)
			if (this._mounted) {
				this.setState({
					searched: true
				})
			}
		}
	}

	formatSearchResult = () => {
		const { t, search, formValues, interakcia, change } = this.props
		const { obchodniPartneri, isLoading, isFailure, isReady } = search

		if (!isReady) {
			return null
		}
		if (isLoading) {
			return (
				<div className='cornered-loading' style={{ minHeight: '118px' }}>
					<ElementLoading />
				</div>
			)
		}
		if (isFailure) {
			return (
				<div className='alert' data-color='red'>
					{t('containers:SearchPage.Pri vyhľadávaní obchodných partnerov nastala chyba')}
				</div>
			)
		}

		return (
			<div>
				<table className='search-results-table no-pointer' cellSpacing='0'>
					<thead>
						<tr>
							<th className='partner-number'>{t('containers:SearchPage.Číslo OP')}</th>
							<th>{t('containers:SearchPage.Meno')}</th>
							<th>{t('containers:SearchPage.Adresa')}</th>
							<th className='date-of-birth'>{t('containers:SearchPage.Dátum narodenia')}</th>
							<th className='phone-column'>{t('containers:SearchPage.Telefón')}</th>
							<th>{t('containers:SearchPage.E-mail')}</th>
							<th className='checkbox-column' />
						</tr>
					</thead>
					<tbody
						data-empty={t(
							'containers:SearchPage.Nenašli sa žiadne výsledky Použite všeobecnejšie parametre vyhľadávania Napr kombináciu meno, priezvisko, adresa, dátum narodenia alebo email'
						)}
					>
						{map(obchodniPartneri, (obchodnyPartner, index) => (
							<SearchResultTableRow
								checkRows
								key={`res${index}`}
								{...obchodnyPartner}
								selectedOP={get(formValues, 'selectedOP')}
								onClick={() => {}}
								disabledCheckbox={get(obchodnyPartner, 'cislo') == get(interakcia, 'data.opCislo')}
								disabledCheckboxText={t('components:SplnomocneniaSearch.Nie je možné splnomocniť sám seba!')}
								onClickCheckbox={() => {
									const selectedOp = get(formValues, 'selectedOP') == obchodnyPartner.cislo ? null : obchodnyPartner.cislo // toogle
									change('selectedOP', selectedOp)
									change('splnomocnenaOsoba', celeMeno(obchodnyPartner))
									change('mode', 'EXISTING_OP')
								}}
							/>
						))}
					</tbody>
				</table>
			</div>
		)
	}

	render() {
		const {
			handleSubmit,
			invalid,
			interakcia,
			t,
			validacneKriteria,
			schvalovacieKriteria,
			formTitle,
			onBackClick,
			formValues,
			formAddresses,
			search,
			change
		} = this.props
		const { searched, titulyPredMenomOptions, titulyZaMenomOptions, oslovenieOptions } = this.state

		const disableForm = !!get(formValues, 'selectedOP')
		const adresaZakaznika = formatAddressForResponse('adresaZakaznika', formValues, formAddresses)

		// result from procesnyKonfigurator if form is valid
		const passValidacneKriterium = every(filter(validacneKriteria, isValidacneKriteriumError), { vysledok: true })
		const errorSchvalovacieKriterium = some(schvalovacieKriteria, { error: true })

		const isValid = passValidacneKriterium && !errorSchvalovacieKriterium

		let refuseEdit
		if (get(formValues, 'adresaZakaznika.id') === get(formValues, 'adresaKorespondencna.id')) {
			refuseEdit = t('components:SplnomocneniaSearch.Adresu nie je možné editovať nakoľko je použitá ako adresa trvalého pobytu')
		}

		const showCreateOPBtn =
			get(formValues, 'mode') == 'NEW_OP' ||
			(isEmpty(get(search, 'obchodniPartneri')) && searched && !get(search, 'isLoading')) ||
			(!isEmpty(get(search, 'obchodniPartneri')) && !find(get(search, 'status'), (status) => get(status, 'kod') == 'warning.OP.unallowedMaxRows'))
		const disableSubmit = !isValid || invalid || (get(formValues, 'mode') == 'EXISTING_OP' && !get(formValues, 'selectedOP'))

		const submitBtn = (
			<button
				className={cx('button', 'pull-right', { disabled: disableSubmit })}
				disabled={disableSubmit}
				type='submit'
				data-color='blue'
				style={{ marginLeft: '20px' }}
			>
				{t('components:SplnomocneniaSearch.Pokračovať')}
			</button>
		)

		let wrappedSubmitBtn
		if (disableSubmit) {
			wrappedSubmitBtn = (
				<Tooltip
					className='pull-right'
					title={t('components:SplnomocneniaSearch.Zvoľte OP alebo vytvorte nového')}
					position='top-start'
					trigger='mouseenter'
					theme='light'
				>
					{submitBtn}
				</Tooltip>
			)
		} else {
			wrappedSubmitBtn = submitBtn
		}

		const createOPBtn = (
			<button
				className={cx('button', { disabled: disableForm })}
				disabled={disableForm}
				type='button'
				data-color='blue'
				style={{ margin: '30px 0 20px 0' }}
				onClick={() => change('mode', 'NEW_OP')}
			>
				{t('components:SplnomocneniaSearch.Založiť OP')}
			</button>
		)

		let wrappedCreateOPBtn
		if (showCreateOPBtn) {
			if (disableForm) {
				wrappedCreateOPBtn = (
					<Tooltip
						title={t('components:SplnomocneniaSearch.Máte zvoleného OP zo zoznamu existujúcich OP')}
						position='top'
						trigger='mouseenter'
						theme='light'
					>
						{createOPBtn}
					</Tooltip>
				)
			} else {
				wrappedCreateOPBtn = createOPBtn
			}
		}

		return (
			<form onSubmit={handleSubmit}>
				<div className='content-header clearfix'>
					{wrappedSubmitBtn}
					<button onClick={onBackClick} type='button' className='button pull-left' data-type='back-button' data-color='blue'>
						{t('components:SplnomocneniaSearch.Späť')}
					</button>
					<div className='header-title pull-left'>{formTitle}</div>
					<Link
						to={setRouteParams(VZTAHY_ZOZNAM, get(interakcia, 'data.opCislo'))}
						className='button pull-right'
						data-type='outline'
						data-color='red'
					>
						{t('components:SplnomocneniaSearch.Zrušiť')}
					</Link>
				</div>
				<div className='content-wrapper'>
					<div className='box'>
						<div className='row'>
							<div className='col-12'>
								<div className='search-wrapper no-border'>
									<input
										type='text'
										autoComplete='false'
										value={this.state.searchSlug}
										onChange={(e) => this.setState({ searchSlug: e.target.value })}
										onKeyDown={(e) => {
											// start searching on key press Enter
											if (e.keyCode === 13) {
												e.target.blur() // call blur cause CARE-790
												this.startSearch()
											}
										}}
										placeholder={t('containers:SearchPage.Hľadať podľa mena, adresy alebo čísla OP')}
									/>
									<div
										className='button circle search-button'
										data-type='outline'
										data-color='blue'
										style={{ backgroundImage: `url(${SearchIcon})` }}
										onClick={this.startSearch}
									/>
								</div>
							</div>
						</div>
						{this.formatSearchResult()}
						<div className='text-center'>{wrappedCreateOPBtn}</div>
					</div>
					{get(formValues, 'mode') == 'NEW_OP' && (
						<div className='box'>
							<div className='box-content'>
								<div className='inner-box'>
									<table className='content-table padded bordered' cellSpacing='0'>
										<tbody>
											{/* NOTE: CP-1234 <tr>
											<td>
												<strong>{t('components:SplnomocneniaSearch.Druh')}</strong>
											</td>
											<td>
												<div className="input-wrapper">
													<Field
														name="druh"
														disabled={disableForm}
														component={SelectField}
														options={druhOptions}
														isClearable
														classNamePrefix="react-select"
														placeholder={t('components:SplnomocneniaSearch.Druh')}
													/>
												</div>
											</td>
										</tr> */}
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Oslovenie')}</strong>
												</td>
												<td>
													<Field
														name='oslovenie'
														disabled={disableForm}
														component={SelectField}
														options={oslovenieOptions}
														isClearable
														classNamePrefix='react-select'
														placeholder={t('components:SplnomocneniaSearch.Oslovenie')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Meno')}</strong>
												</td>
												<td>
													<div className='input-wrapper'>
														<Field
															name='meno'
															disabled={disableForm}
															component={TextInputField}
															placeholder={t('components:SplnomocneniaSearch.Meno')}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Priezvisko')}</strong>
												</td>
												<td>
													<div className='input-wrapper'>
														<Field
															name='priezvisko'
															component={TextInputField}
															disabled={disableForm}
															placeholder={t('components:SplnomocneniaSearch.Priezvisko')}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Titul pred menom')}</strong>
												</td>
												<td>
													<div className='row'>
														<div className='col-6'>
															<Field
																name='titulPred1'
																component={SelectField}
																options={titulyPredMenomOptions}
																isClearable={!this.state.titulPred2Focus}
																isDisabled={titulyPredMenomOptions.length == 0 || disableForm}
																classNamePrefix='react-select'
															/>
														</div>

														<div className='col-6'>
															<Field
																name='titulPred2'
																component={SelectField}
																options={titulyPredMenomOptions}
																isClearable
																isDisabled={titulyPredMenomOptions.length == 0 || !formValues.titulPred1 || disableForm}
																classNamePrefix='react-select'
																onMenuOpen={() => this.setState({ titulPred2Focus: true })}
																onMenuClose={() => this.setState({ titulPred2Focus: false })}
															/>
														</div>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Titul za menom')}</strong>
												</td>
												<td>
													<Field
														name='titulZa'
														component={SelectField}
														options={titulyZaMenomOptions}
														isClearable
														isDisabled={titulyZaMenomOptions.length == 0 || disableForm}
														classNamePrefix='react-select'
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Dátum narodenia')}</strong>
												</td>
												<td>
													<Field
														name='narodenieDatum'
														component={DatePickerField}
														showYearDropdown
														dateFormatCalendar='MMMM'
														scrollableYearDropdown
														yearDropdownItemNumber={80}
														isClearable
														minDate={dayjs().add(-120, 'years').toDate()}
														maxDate={dayjs().add(-18, 'years').toDate()}
														placeholderText={t('components:SplnomocneniaSearch.Zvoľte dátum narodenia')}
														disabled={disableForm}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Adresa trvalého pobytu')}</strong>
												</td>
												<td>
													<Fields
														names={['adresaZakaznika.id', 'adresaZakaznika.globalnaZmena']}
														selector={'adresaZakaznika'}
														component={AddressField}
														disabled={disableForm}
														create
														globalnaZmenaLabel={t('components:SplnomocneniaSearch.Uplatniť túto adresu na celom OP')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Kontaktné telefónne číslo')}</strong>
												</td>
												<td>
													<Field
														disabled={disableForm}
														name='telefonneCislo'
														component={TextInputField}
														placeholder={t('components:SplnomocneniaSearch.Zadajte kontaktné telefónne číslo')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Kontaktný email')}</strong>
												</td>
												<td>
													<Field
														disabled={disableForm}
														name='kontaktnyEmail'
														component={TextInputField}
														placeholder={t('components:SplnomocneniaSearch.Zadajte kontaktný email')}
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{t('components:SplnomocneniaSearch.Korešpondenčná adresa')}</strong>
												</td>

												<td>
													{!get(formValues, 'adresaZakaznika.globalnaZmena') ? (
														<Fields
															names={['adresaKorespondencna.id']}
															create
															edit
															refuseEdit={refuseEdit}
															selector={'adresaKorespondencna'}
															component={AddressField}
															disabled={get(formValues, 'adresaZakaznika.globalnaZmena') || disableForm}
														/>
													) : (
														formatAddress(adresaZakaznika)
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.SPLNOMOCNENIA_ZALOZENIE_OP,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate
})(SplnomocneniaSearch)

const mapStateToProps = (state) => ({
	interakcia: state.interakcie.detail,
	ciselniky: state.ciselniky.data,
	actualBusinessChannel: state.auth.businessChannel.actual,
	formAddresses: state.formAddresses.data,
	formValues: getFormValues(FORMS.SPLNOMOCNENIA_ZALOZENIE_OP)(state)
})

export default compose(withTranslation('components'), connect(mapStateToProps))(form)
